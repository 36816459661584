<template>
  <div class="flex-auto column" style="margin-bottom: 0;">
    <div class="padding-bottom-10 border-bottom bold">
      <span>资料认证</span>
      <span v-if="formData.zizhi_id" :class="`${formData.audit_state==2?'color-green':'color-orange'} font-size-12`">
        （{{formData.audit_state==0?'请提交认证资料':formData.audit_state==1?'资料认证审核中':formData.audit_state==2?'资料认证已通过':'资料认证已驳回，请核实后重新提交'}}）
      </span>
    </div>
    <div class="flex-row align-items-center padding-tb-20">
      <span class="color-sub">请选择认证类型：</span>
      <!-- <el-radio-group v-model="dw_type" :disabled="formData.zizhi_id?true:false"> -->
      <el-radio-group v-model="dw_type" :disabled="formData.zizhi_id?true:false">
        <el-radio v-for="(item,index) in baseData.dw_type" :key="index" :label="item"></el-radio>
      </el-radio-group>
    </div>
    <FormDtyd v-if="dw_type=='单体药店'" dwType="单体药店" :areaSelectData="areaSelectData" :baseData="baseData" :formData="formData" @submit="submitData"></FormDtyd>
    <FormLslspf v-if="dw_type=='零售连锁、药品批发零售'" dwType="零售连锁、药品批发零售" :areaSelectData="areaSelectData" :baseData="baseData" :formData="formData" @submit="submitData"></FormLslspf>
    <FormHospital v-if="dw_type=='医院'" dwType="医院" :areaSelectData="areaSelectData" :baseData="baseData" :formData="formData" @submit="submitData"></FormHospital>
    <FormScqy v-if="dw_type=='生产企业'" dwType="生产企业" :areaSelectData="areaSelectData" :baseData="baseData" :formData="formData" @submit="submitData"></FormScqy>
    <FormOther v-if="dw_type=='其他'" dwType="其他" :areaSelectData="areaSelectData" :baseData="baseData" :formData="formData" @submit="submitData"></FormOther>
  </div>
</template>

<script>
import FormDtyd from "./formComponents/FormDtyd";
import FormLslspf from "./formComponents/FormLslspf";
import FormHospital from "./formComponents/FormHospital";
import FormScqy from "./formComponents/FormScqy";
import FormOther from "./formComponents/FormOther";
export default {
  components: {FormDtyd,FormLslspf,FormHospital,FormScqy,FormOther },
  name: 'Authentication',
  data() {
    return {
      baseData:{},
      areaSelectData:[],
      dw_type:'单体药店',
      formData:{},
    }
  },
  computed:{
    
  },
  methods:{
    //获取地区选择器数据
    getAreaData(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/address/get_district_config',{params})
      .then(res=>{
       
        if(res.data.code==0){
          this.areaSelectData=res.data.data
        }
      })
      .catch(()=> {}); 
    },
     //获取基础字典数据
     getBaseData(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/user/get_auth_data_dict',{params})
      .then(res=>{
       
        if(res.data.code==0){
          this.baseData=res.data.data
        }
      })
      .catch(()=> {}); 
    },

    submitData(form){
      this.$instance.post(form.zizhi_id?'/user/edit_auth_info':'/user/post_auth_info',form)
      .then(res=>{
        if(res.data.code==0){
          this.getData()
          this.$alert(`提交成功,请等待审核!`, '提示', {
            customClass:"custom-msgbox",
            confirmButtonText: '确定',
            showClose:false,
            type: 'success'
          })
          .then(() => {
            document.body.scrollTop=document.documentElement.scrollTop=0
          })
        }
      })
      .catch(()=>{})
    },
    getData(){
      this.$instance.get('/user/get_auth_info',{
        params:{
          ...this.$store.state.basicParams,
        }
      })
      .then(res=>{
        if(res.data.code==0){
          let form=res.data.data||{}
          this.dw_type=res.data.data.dw_type
          for(let key in form){
            if(this.$dataType(form[key])=='object'){
              form[key]={...form[key]}
              for(let key_sub in form[key]){
                if(key_sub.indexOf('imgs')>=0&&this.$dataType(form[key][key_sub])=='array'){
                  form[key][key_sub]=form[key][key_sub].map(v=>{return {url:v.img_url, img_relative_file_path: v.img_relative_file_path,type:[key,key_sub]}})
                }
              }
            }
          }
          if(form.common_info&&form.common_info.szdq_sheng){
            form.common_info.area=[form.common_info.szdq_sheng,form.common_info.szdq_shi,form.common_info.szdq_qu]
          }
          if(form.yaopin_jingying_xuke&&form.yaopin_jingying_xuke.szdq_sheng){
            form.yaopin_jingying_xuke.area=[form.yaopin_jingying_xuke.szdq_sheng,form.yaopin_jingying_xuke.szdq_shi,form.yaopin_jingying_xuke.szdq_qu]
          }
          this.formData=form
          this.$store.commit('loginRes',{...this.$store.state.loginRes,audit_state:this.formData.audit_state})
        }else if(res.data.code==1061){
          this.$message.closeAll()
        }
      })
      .catch(()=>{})
    },
   
  },
  created(){
    if(this.$store.state.loginRes.can_edit==1){
      this.getAreaData()
      this.getBaseData()
      this.getData()
    }else{
      this.$alert('您的资质已认证通过，如需修改，请联系银河药业质量管理部！', '提示', {
        customClass:"custom-msgbox",
        confirmButtonText: '确定',
        showClose:false,
        type: 'info'
      })
      .then(() => {
       
          this.$root.jump({
            path:'/'
          })

      })
    }
  },
  mounted(){ 
    
  },
  beforeDestroy() {
   
  },
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
<style>

</style>

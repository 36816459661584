<template>
  <div>
    <!-- {{form}} -->
    <!-- {{ formData }} -->
    <el-form :model="form" ref="form" label-width="auto">
      <!-- 公用信息 -->
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>基本信息</div>
        </div>
        <div class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="类型"  prop="lei_xing" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-select v-model="form.lei_xing" style="width: 100%;" :disabled="form.zizhi_id?true:false">
                  <el-option v-for="(item,index) in (baseData.pifa_lingshou||{}).lei_xing" :key="index" :value="item" :label="item"></el-option>
                </el-select>
              </el-form-item> 
            </el-col>
            <el-col :span="12">
              <el-form-item label="客户名称"  prop="common_info.kehu_name" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.common_info.kehu_name" autocomplete="off" placeholder="请输入" maxlength="20"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="法人"  prop="common_info.faren" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.common_info.faren" autocomplete="off"  placeholder="请输入"  maxlength="20"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="负责人"  prop="common_info.fuzeren" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.common_info.fuzeren" autocomplete="off" placeholder="请输入"  maxlength="20"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="质量负责人"  prop="common_info.zhiliang_fzr" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.common_info.zhiliang_fzr" autocomplete="off" placeholder="请输入"  maxlength="20"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话"  prop="common_info.lianxi_tel" :rules="[{ required: true, message: '此项不能为空'},{validator:rules.validatePhone,trigger: 'blur'}]">
                <el-input v-model.trim="form.common_info.lianxi_tel" autocomplete="off" placeholder="请输入手机号" maxlength="11"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所在地区"  prop="common_info.area" :rules="[{ required: true, message: '此项不能为空',trigger:'change'}]">
                <el-cascader
                  v-model.trim="form.common_info.area"
                  style="width:100%"
                  :options="areaSelectData"
                  :props="{ expandTrigger: 'hover',label:'name',children:'children',value:'name' }"
                  filterable
                  placeholder="请选择"
                  @change="form.common_info.szdq_sheng=(form.common_info.area||[])[0];form.common_info.szdq_shi=(form.common_info.area||[])[1];form.common_info.szdq_qu=(form.common_info.area||[])[2]"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="注册地址"  prop="common_info.register_address" :rules="[{ required: true, message: '此项不能为空'}]">
                  <el-input v-model.trim="form.common_info.register_address" autocomplete="off" placeholder="请输入"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="仓库地址"  prop="common_info.cangku_address" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.common_info.cangku_address" autocomplete="off" placeholder="请输入"></el-input>
                </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 营业执照 -->
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>营业执照</div>
        </div>
        <div class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="营业执照图片" prop="yingye_zhizhao.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['yingye_zhizhao','imgs']}"
                  :file-list="form.yingye_zhizhao.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="营业执照名称"  prop="yingye_zhizhao.name" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.yingye_zhizhao.name" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="社会统一信用代码"  prop="yingye_zhizhao.daima" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.yingye_zhizhao.daima" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="证件有效期" required>
                <div class="flex-row align-items-center">
                  <el-form-item  prop="yingye_zhizhao.yxq_start" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.yingye_zhizhao.yxq_start"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() > (form.yingye_zhizhao.yxq_end?new Date(form.yingye_zhizhao.yxq_end).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <div class="margin-lr-10">至</div>
                  <el-form-item  prop="yingye_zhizhao.yxq_end" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.yingye_zhizhao.yxq_end"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() < (form.yingye_zhizhao.yxq_start?new Date(form.yingye_zhizhao.yxq_start).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 药品经营许可证 -->
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>药品经营许可证</div>
        </div>
        <div class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="许可证图片" prop="yaopin_jingying_xuke.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['yaopin_jingying_xuke','imgs']}"
                  :file-list="form.yaopin_jingying_xuke.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="许可证上名称"  prop="yaopin_jingying_xuke.name" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.yaopin_jingying_xuke.name" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="许可证号"  prop="yaopin_jingying_xuke.daima" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.yaopin_jingying_xuke.daima" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所在地区"  prop="yaopin_jingying_xuke.area" :rules="[{ required: true, message: '此项不能为空',trigger:'change'}]">
                <el-cascader
                  v-model.trim="form.yaopin_jingying_xuke.area"
                  style="width:100%"
                  :options="areaSelectData"
                  :props="{ expandTrigger: 'hover',label:'name',children:'children',value:'name' }"
                  filterable
                  placeholder="请选择"
                  @change="form.yaopin_jingying_xuke.szdq_sheng=(form.yaopin_jingying_xuke.area||[])[0];form.yaopin_jingying_xuke.szdq_shi=(form.yaopin_jingying_xuke.area||[])[1];form.yaopin_jingying_xuke.szdq_qu=(form.yaopin_jingying_xuke.area||[])[2]"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="注册地址"  prop="yaopin_jingying_xuke.register_address" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.yaopin_jingying_xuke.register_address" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="仓库地址"  prop="yaopin_jingying_xuke.cangku_address" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.yaopin_jingying_xuke.cangku_address" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="经营许可范围"  prop="yaopin_jingying_xuke.jingying_scope" :rules="[{ required: true, message: '此项不能为空'}]">
                <span slot="label" style="display:inline-block;line-height: 1.2em;vertical-align: middle;">
                  <span>经营许可范围</span>
                  <div class="color-sub font-size-12">(输入关键字搜索)</div>
                </span>
                <el-select
                  v-model.trim="form.yaopin_jingying_xuke.jingying_scope"
                  multiple
                  filterable
                  remote
                  :reserve-keyword="false"
                  placeholder="请输入关键词搜索"
                  :loading="scope_search_loading"
                  :remote-method="(keyword)=>{scope_search(keyword,'经营许可范围')}"
                  @visible-change="scope_list = [];"
                  style="width:100%;"
                >
                  <el-option
                    v-for="(item,index) in scope_list"
                    :key="index"
                    :label="item.search_result"
                    :value="item.search_result">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="证件有效期" required>
                <div class="flex-row align-items-center">
                  <el-form-item  prop="yaopin_jingying_xuke.yxq_start" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.yaopin_jingying_xuke.yxq_start"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() > (form.yaopin_jingying_xuke.yxq_end?new Date(form.yaopin_jingying_xuke.yxq_end).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <div class="margin-lr-10">至</div>
                  <el-form-item  prop="yaopin_jingying_xuke.yxq_end" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.yaopin_jingying_xuke.yxq_end"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() < (form.yaopin_jingying_xuke.yxq_start?new Date(form.yaopin_jingying_xuke.yxq_start).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 采购委托书 -->
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>采购委托书</div>
          <!-- <el-switch v-model="form.cgwts_is_fill" :active-value="1" :inactive-value="0" disabled></el-switch> -->
        </div>
        <div v-if="form.cgwts_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="采购委托书图片" prop="caigou_weituoshu.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['caigou_weituoshu','imgs']}"
                  :file-list="form.caigou_weituoshu.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="委托书有效期" required>
                <div class="flex-row align-items-center">
                  <el-form-item  prop="caigou_weituoshu.yxq_start" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.caigou_weituoshu.yxq_start"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() > (form.caigou_weituoshu.yxq_end?new Date(form.caigou_weituoshu.yxq_end).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <div class="margin-lr-10">至</div>
                  <el-form-item  prop="caigou_weituoshu.yxq_end" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.caigou_weituoshu.yxq_end"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() < (form.caigou_weituoshu.yxq_start?new Date(form.caigou_weituoshu.yxq_start).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="授权类型"  prop="caigou_weituoshu.sq_type" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-select v-model.trim="form.caigou_weituoshu.sq_type" style="width: 100%;" @change="form.caigou_weituoshu.sq_scope = [];">
                  <el-option v-for="(item,index) in (baseData.pifa_lingshou||{}).shouquan_type" :key="index" :value="item" :label="item"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="授权范围"  prop="caigou_weituoshu.sq_scope" :rules="[{ required: true, message: '此项不能为空'}]">
                <span slot="label" style="display:inline-block;line-height: 1.2em;vertical-align: middle;">
                  <span>授权范围</span>
                  <div class="color-sub font-size-12">(输入关键字搜索)</div>
                </span>
                <el-select
                  v-model.trim="form.caigou_weituoshu.sq_scope"
                  multiple
                  filterable
                  remote
                  :reserve-keyword="false"
                  placeholder="请输入关键词搜索"
                  :loading="scope_search_loading"
                  :remote-method="(keyword)=>{scope_search(keyword,form.caigou_weituoshu.sq_type,'请选择授权类型')}"
                  @visible-change="scope_list = [];"
                  style="width:100%;"
                >
                  <el-option
                    v-for="(item,index) in scope_list"
                    :key="index"
                    :label="form.caigou_weituoshu.sq_type=='品种'?item.spmch:item.search_result"
                    :value="form.caigou_weituoshu.sq_type=='品种'?item.spmch:item.search_result">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="采购人身份证" prop="caigou_weituoshu.sfz_imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['caigou_weituoshu','sfz_imgs']}"
                  :file-list="form.caigou_weituoshu.sfz_imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="采购人姓名"  prop="caigou_weituoshu.cgr_name" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.caigou_weituoshu.cgr_name" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">  
              <el-form-item label="采购人身份证号"  prop="caigou_weituoshu.cgr_sfz_no" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.caigou_weituoshu.cgr_sfz_no" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="采购人身份证有效期" required>
                <div class="flex-row align-items-center">
                  <el-form-item  prop="caigou_weituoshu.sfz_yxq_start" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.caigou_weituoshu.sfz_yxq_start"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() > (form.caigou_weituoshu.sfz_yxq_end&&form.caigou_weituoshu.sfz_yxq_end!='长期'?new Date(form.caigou_weituoshu.sfz_yxq_end).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <div class="margin-lr-10">至</div>
                  <el-form-item v-if="form.caigou_weituoshu.sfz_yxq_end!='长期'"  prop="caigou_weituoshu.sfz_yxq_end" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.caigou_weituoshu.sfz_yxq_end"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() < (form.caigou_weituoshu.sfz_yxq_start?new Date(form.caigou_weituoshu.sfz_yxq_start).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <span class="margin-left-10 link" @click="form.caigou_weituoshu.sfz_yxq_end!='长期'?$set(form.caigou_weituoshu,'sfz_yxq_end','长期'):$set(form.caigou_weituoshu,'sfz_yxq_end','')">
                    <i :class="form.caigou_weituoshu.sfz_yxq_end=='长期'?'fa fa-check-square-o':'fa fa-square-o'"></i> 长期
                  </span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 收货委托书 -->
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>收货委托书</div>
          <!-- <el-switch v-model="form.shwts_is_fill" :active-value="1" :inactive-value="0" disabled></el-switch> -->
        </div>
        <div v-if="form.shwts_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="收货委托书图片" prop="shouhuo_weituoshu.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['shouhuo_weituoshu','imgs']}"
                  :file-list="form.shouhuo_weituoshu.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="委托书有效期" required>
                <div class="flex-row align-items-center">
                  <el-form-item  prop="shouhuo_weituoshu.yxq_start" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.shouhuo_weituoshu.yxq_start"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() > (form.shouhuo_weituoshu.yxq_end?new Date(form.shouhuo_weituoshu.yxq_end).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <div class="margin-lr-10">至</div>
                  <el-form-item  prop="shouhuo_weituoshu.yxq_end" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.shouhuo_weituoshu.yxq_end"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() < (form.shouhuo_weituoshu.yxq_start?new Date(form.shouhuo_weituoshu.yxq_start).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="收货人身份证" prop="shouhuo_weituoshu.sfz_imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['shouhuo_weituoshu','sfz_imgs']}"
                  :file-list="form.shouhuo_weituoshu.sfz_imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收货人姓名"  prop="shouhuo_weituoshu.shr_name" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.shouhuo_weituoshu.shr_name" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收货人身份证号"  prop="shouhuo_weituoshu.shr_sfz_no" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.shouhuo_weituoshu.shr_sfz_no" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="收货人身份证有效期" required>
                <div class="flex-row align-items-center">
                  <el-form-item  prop="shouhuo_weituoshu.sfz_yxq_start" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.shouhuo_weituoshu.sfz_yxq_start"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() > (form.shouhuo_weituoshu.sfz_yxq_end&&form.shouhuo_weituoshu.sfz_yxq_end!='长期'?new Date(form.shouhuo_weituoshu.sfz_yxq_end).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <div class="margin-lr-10">至</div>
                  <el-form-item v-if="form.shouhuo_weituoshu.sfz_yxq_end!='长期'"  prop="shouhuo_weituoshu.sfz_yxq_end" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.shouhuo_weituoshu.sfz_yxq_end"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() < (form.shouhuo_weituoshu.sfz_yxq_start?new Date(form.shouhuo_weituoshu.sfz_yxq_start).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <span class="margin-left-10 link" @click="form.shouhuo_weituoshu.sfz_yxq_end!='长期'?$set(form.shouhuo_weituoshu,'sfz_yxq_end','长期'):$set(form.shouhuo_weituoshu,'sfz_yxq_end','')">
                    <i :class="form.shouhuo_weituoshu.sfz_yxq_end=='长期'?'fa fa-check-square-o':'fa fa-square-o'"></i> 长期
                  </span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 药品经营许可证变更记录 -->
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>药品经营许可证变更记录</div>
          <el-switch v-model="form.ypjyxk_bg_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.ypjyxk_bg_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="图片上传" prop="ypjyxk_bg.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['ypjyxk_bg','imgs']}"
                  :file-list="form.ypjyxk_bg.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        
      </div>
      <!-- 上一年度企业年度报告 -->
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>上一年度企业年度报告</div>
          <el-switch v-model="form.ndbg_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.ndbg_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="图片上传" prop="niandu_baogao.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['niandu_baogao','imgs']}"
                  :file-list="form.niandu_baogao.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="起止时间" required>
                <div class="flex-row align-items-center">
                  <el-form-item  prop="niandu_baogao.start_date" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.niandu_baogao.start_date"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() > (form.niandu_baogao.end_date?new Date(form.niandu_baogao.end_date).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <div class="margin-lr-10">至</div>
                  <el-form-item  prop="niandu_baogao.end_date" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.niandu_baogao.end_date"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() < (form.niandu_baogao.start_date?new Date(form.niandu_baogao.start_date).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 质量保证协议 -->
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>质量保证协议（<a download="质量保证协议" target="_blank" :href="(baseData.pifa_lingshou||{}).zlxy_template" class="color-blue link-text">模板下载</a>）</div>
          <el-switch v-model="form.zlxy_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.zlxy_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="图片上传" prop="zhiliang_xieyi.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['zhiliang_xieyi','imgs']}"
                  :file-list="form.zhiliang_xieyi.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="起止时间" required>
                <div class="flex-row align-items-center">
                  <el-form-item  prop="zhiliang_xieyi.start_date" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.zhiliang_xieyi.start_date"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() > (form.zhiliang_xieyi.end_date?new Date(form.zhiliang_xieyi.end_date).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <div class="margin-lr-10">至</div>
                  <el-form-item  prop="zhiliang_xieyi.end_date" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.zhiliang_xieyi.end_date"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() < (form.zhiliang_xieyi.start_date?new Date(form.zhiliang_xieyi.start_date).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- GSP -->
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>GSP</div>
          <el-switch v-model="form.gsp_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.gsp_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="图片上传" prop="gsp.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['gsp','imgs']}"
                  :file-list="form.gsp.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="证号"  prop="gsp.gsp_no" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.gsp.gsp_no" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="有效期" required>
                <div class="flex-row align-items-center">
                  <el-form-item  prop="gsp.start_date" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.gsp.start_date"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() > (form.gsp.end_date?new Date(form.gsp.end_date).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <div class="margin-lr-10">至</div>
                  <el-form-item  prop="gsp.end_date" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.gsp.end_date"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() < (form.gsp.start_date?new Date(form.gsp.start_date).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>第二类医疗器械经营</div>
          <el-switch v-model.trim="form.el_ylqx_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.el_ylqx_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="备案凭证" prop="erlei_yiliao_qixie.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['erlei_yiliao_qixie','imgs']}"
                  :file-list="form.erlei_yiliao_qixie.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备案凭证证号"  prop="erlei_yiliao_qixie.beian_no" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.erlei_yiliao_qixie.beian_no" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发证日期"  prop="erlei_yiliao_qixie.fazheng_date" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-date-picker style="width:100%;"
                  v-model.trim="form.erlei_yiliao_qixie.fazheng_date"
                  format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期"
                  :picker-options="{ disabledDate: time => {return time.getTime() > Date.now();}}">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备案范围"  prop="erlei_yiliao_qixie.beian_scpoe" :rules="[{ required: true, message: '此项不能为空'}]">
                <span slot="label" style="display:inline-block;line-height: 1.2em;vertical-align: middle;">
                  <span>备案范围</span>
                  <div class="color-sub font-size-12">(输入关键字搜索)</div>
                </span>
                <el-select
                  v-model.trim="form.erlei_yiliao_qixie.beian_scpoe"
                  multiple
                  filterable
                  remote
                  :reserve-keyword="false"
                  placeholder="请输入关键词搜索"
                  :loading="scope_search_loading"
                  :remote-method="(keyword)=>{scope_search(keyword,'备案范围')}"
                  @visible-change="scope_list = [];"
                  style="width:100%;"
                >
                  <el-option
                    v-for="(item,index) in scope_list"
                    :key="index"
                    :label="item.search_result"
                    :value="item.search_result">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>医疗器械经营</div>
          <el-switch v-model.trim="form.ylqx_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.ylqx_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="许可证图片" prop="yiliao_qixie.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['yiliao_qixie','imgs']}"
                  :file-list="form.yiliao_qixie.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="许可证证号"  prop="yiliao_qixie.xkz_no" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.yiliao_qixie.xkz_no" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            
            <el-col :span="24">
              <el-form-item label="许可证范围"  prop="yiliao_qixie.xuke_scpoe" :rules="[{ required: true, message: '此项不能为空'}]">
                <span slot="label" style="display:inline-block;line-height: 1.2em;vertical-align: middle;">
                  <span>许可证范围</span>
                  <div class="color-sub font-size-12">(输入关键字搜索)</div>
                </span>
                <el-select
                  v-model.trim="form.yiliao_qixie.xuke_scpoe"
                  multiple
                  filterable
                  remote
                  :reserve-keyword="false"
                  placeholder="请输入关键词搜索"
                  :loading="scope_search_loading"
                  :remote-method="(keyword)=>{scope_search(keyword,'许可证范围')}"
                  @visible-change="scope_list = [];"
                  style="width:100%;"
                >
                  <el-option
                    v-for="(item,index) in scope_list"
                    :key="index"
                    :label="item.search_result"
                    :value="item.search_result">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="证件有效期" required>
                <div class="flex-row align-items-center">
                  <el-form-item  prop="yiliao_qixie.yxq_start" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.yiliao_qixie.yxq_start"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() > (form.yiliao_qixie.yxq_end?new Date(form.yiliao_qixie.yxq_end).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <div class="margin-lr-10">至</div>
                  <el-form-item  prop="yiliao_qixie.yxq_end" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.yiliao_qixie.yxq_end"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() < (form.yiliao_qixie.yxq_start?new Date(form.yiliao_qixie.yxq_start).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>非药品经营</div>
          <el-switch v-model.trim="form.fyp_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.fyp_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="许可证图片" prop="feiyaopin.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['feiyaopin','imgs']}"
                  :file-list="form.feiyaopin.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="许可证证号"  prop="feiyaopin.xkz_no" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.feiyaopin.xkz_no" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            
            <el-col :span="24">
              <el-form-item label="许可证范围"  prop="feiyaopin.xuke_scpoe" :rules="[{ required: true, message: '此项不能为空'}]">
                <span slot="label" style="display:inline-block;line-height: 1.2em;vertical-align: middle;">
                  <span>许可证范围</span>
                  <div class="color-sub font-size-12">(输入关键字搜索)</div>
                </span>
                <el-select
                  v-model.trim="form.feiyaopin.xuke_scpoe"
                  multiple
                  filterable
                  remote
                  :reserve-keyword="false"
                  placeholder="请输入关键词搜索"
                  :loading="scope_search_loading"
                  :remote-method="(keyword)=>{scope_search(keyword,'许可证范围')}"
                  @visible-change="scope_list = [];"
                  style="width:100%;"
                >
                  <el-option
                    v-for="(item,index) in scope_list"
                    :key="index"
                    :label="item.search_result"
                    :value="item.search_result">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="证件有效期" required>
                <div class="flex-row align-items-center">
                  <el-form-item  prop="feiyaopin.yxq_start" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.feiyaopin.yxq_start"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() > (form.feiyaopin.yxq_end?new Date(form.feiyaopin.yxq_end).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                  <div class="margin-lr-10">至</div>
                  <el-form-item  prop="feiyaopin.yxq_end" :rules="[{ required: true, message: '此项不能为空'}]" class="flex-auto">
                    <el-date-picker style="width:100%;"
                      v-model.trim="form.feiyaopin.yxq_end"
                      format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="{ disabledDate: time => {return time.getTime() < (form.feiyaopin.yxq_start?new Date(form.feiyaopin.yxq_start).getTime():Date.now());}}">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>开票信息</div>
          <el-switch v-model.trim="form.kpxx_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.kpxx_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="图片上传" prop="kaipiao_info.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['kaipiao_info','imgs']}"
                  :file-list="form.kaipiao_info.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户名称"  prop="kaipiao_info.kaihu_name" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.kaipiao_info.kaihu_name" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="税号"  prop="kaipiao_info.shuihao" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.kaipiao_info.shuihao" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户银行"  prop="kaipiao_info.kaihu_bank" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.kaipiao_info.kaihu_bank" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户行账号"  prop="kaipiao_info.kaihu_account" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.kaipiao_info.kaihu_account" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开票电话"  prop="kaipiao_info.kaipiao_tel" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.kaipiao_info.kaipiao_tel" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开票地址"  prop="kaipiao_info.kaipiao_address" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-input v-model.trim="form.kaipiao_info.kaipiao_address" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>开户许可证/基本账户存款信息</div>
          <el-switch v-model.trim="form.khxkz_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.khxkz_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="图片上传" prop="kaihuxuke.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['kaihuxuke','imgs']}"
                  :file-list="form.kaihuxuke.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>质量体系调查表</div>
          <el-switch v-model.trim="form.zltxdc_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.zltxdc_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="图片上传" prop="zhiliang_tixi_diaocha.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['zhiliang_tixi_diaocha','imgs']}"
                  :file-list="form.zhiliang_tixi_diaocha.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>印章印模</div>
          <el-switch v-model.trim="form.yzym_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.yzym_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="图片上传" prop="yinzhang_yinmo.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['yinzhang_yinmo','imgs']}"
                  :file-list="form.yinzhang_yinmo.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>随货同行模板</div>
          <el-switch v-model.trim="form.shtx_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.shtx_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="图片上传" prop="suihuo_tongxing.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['suihuo_tongxing','imgs']}"
                  :file-list="form.suihuo_tongxing.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="border margin-bottom-20" style="border-radius:5px;">
        <div style="border-radius: 5px; background: #f7f7f7;" class="flex-row align-items-center justify-between bold padding-lr-20 padding-tb-10">
          <div>其他资料</div>
          <el-switch v-model.trim="form.other_info_is_fill" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-if="form.other_info_is_fill" class="padding-lr-20 padding-top-20">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="图片上传" prop="other_xinxi.imgs" :rules="[{ required: true, message: '此项不能为空'}]">
                <el-upload
                  class="no-transition"
                  list-type="picture-card"
                  :limit="10" 
                  action=""
                  :data="{type:['other_xinxi','imgs']}"
                  :file-list="form.other_xinxi.imgs||[]"
                  :http-request="uploadFile"
                  accept=".jpg,.gif,.png,.jpeg" 
                  :multiple="true"
                  :on-exceed="exceed"
                  :before-upload="beforeAvatarUpload"
                  :before-remove="handleFileRemove" 
                  >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-form-item label='备注' label-width="40px">
        <el-input v-model.trim="form.memo_info" autocomplete="off" placeholder="请输入备注"></el-input>
      </el-form-item>
      <el-form-item label-width="0">
        <div class="flex-row align-items-center justify-center">
          <el-tooltip :disabled="form.audit_stat!=2" effect="light" content="重新提交资料后，将会重新认证，审核期间部分功能应用将会受限！" placement="top">
            <el-button class="custom-btn" type="primary" round @click="submitForm('form')">提 交</el-button>
          </el-tooltip>
        </div>
      </el-form-item>
    </el-form>
    
  </div>
</template>

<script>

export default {
  components: { },
  name: 'FormLslspf',
  props:{
    dwType:{
      type:String,
      default:''
    },
    areaSelectData:{
      type:Array,
      default:()=>{
        return []
      }
    },
    baseData:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    formData:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data() {
    return {
      form:{
        lei_xing:'',
        common_info:{
          area:[]
        },
        yingye_zhizhao:{
          // imgs:[
          //   // {url:'http://118.190.98.60:6001/static/adv/1.png', img_relative_file_path: "tmp\\202311\\202311152059293215.jpeg",type:'['yingye_zhizhao','imgs']'}
          // ],
        },
        yaopin_jingying_xuke:{
          area:[],
          jingying_scope:[],
        },
        cgwts_is_fill: 1,
        caigou_weituoshu:{ 
          sq_scope:[],
        },
        shwts_is_fill: 1,
        shouhuo_weituoshu:{
          sfz_imgs:[],
        },
        ypjyxk_bg_is_fill:0,
        ypjyxk_bg:{},
        ndbg_is_fill:0,
        niandu_baogao:{},
        zlxy_is_fill:0,
        zhiliang_xieyi:{},
        gsp_is_fill:0,
        gsp:{},
        el_ylqx_is_fill:0,
        erlei_yiliao_qixie:{
          beian_scpoe:[]
        },
        ylqx_is_fill:0,
        yiliao_qixie:{
          xuke_scpoe:[]
        },
        fyp_is_fill:0,
        feiyaopin:{
          xuke_scpoe:[]
        },
        kpxx_is_fill:0,
        kaipiao_info:{},
        khxkz_is_fill:0,
        kaihuxuke:{},
        zltxdc_is_fill:0,
        zhiliang_tixi_diaocha:{},
        yzym_is_fill:0,
        yinzhang_yinmo:{},
        shtx_is_fill:0,
        suihuo_tongxing:{},
        other_info_is_fill:0,
        other_xinxi:{},
        memo_info:''
      },
      rules:{
        validatePhone:(rule, value, callback)=>{
          let reg = /^1([0-9])\d{9}$/;
          if (reg.test(value)) {
            callback()
          } else {
            callback(new Error('请输入正确手机号'));
          }
        },
      },
      scope_search_loading:false,
      scope_list:[],
      searchTimer:null,
    }
  },
  computed:{
    area_text(){
      let obj=null,
          area_text=[]
      if(this.form.area_code&&this.form.area_code.length&&this.areaSelectData.length){
        this.form.area_code.forEach((code,index) => {
          if(index==0){
            obj=this.areaSelectData.find(v=>{return v.code==code})
          }else{
            obj=obj.children.find(v=>{return v.code==code})
          }
          area_text.push(obj.name)
        });  
      }
      return area_text
    }
  },
  methods:{
    // 范围选项搜索
    scope_search(keyword,search_type,tips){
      this.searchTimer&&clearTimeout(this.searchTimer)
      if (keyword !== '') {
        this.searchTimer=setTimeout(()=>{
           search_type ? this.getScope(keyword,search_type) : this.$message({message: tips||'请选择搜索的授权范围类型!',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        },500)
      } else {
        this.scope_list = [];
      }
    },
    getScope(keyword,search_type){
      this.scope_search_loading=true
      this.$instance.get(search_type=='品种'?'/main/get_search_product_list':'/user/search_auth_info',{
        params:{
          ...this.$store.state.basicParams,
          keyword,
          search_type:search_type!='品种'?search_type:undefined,
          page:search_type=='品种'?1:undefined,
          page_size:search_type=='品种'?1000:undefined,
        }
      })
      .then(res=>{
        this.scope_search_loading=false
        if(res.data.code==0){
          this.scope_list=search_type=='品种'?res.data.data.list:res.data.data
        }
      })
      .catch(()=>{ this.scope_search_loading=false})
    },
    
    //上传相关==============================================================================
    //上传前判断
    beforeAvatarUpload(file) {
      const isJPG = (file.type === 'image/jpeg'||file.type === 'image/jpg'||file.type === 'image/png'||file.type === 'image/gif');
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message({message: '上传头像图片只能是 JPG/JPEG/PNG/GIF 格式!',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      }
      if (!isLt2M) {
        this.$message({message: '上传图片文件单张不能超过5MB!',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      }
      return isJPG && isLt2M;
    },
    //超出上传数量
    exceed(){
      this.$message({message: '只能上传10张图片，请先删除已选文件',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
    },
    // 删除图片钩子
    handleFileRemove(file, fileList) {
      if(file.type){
        this.form[file.type[0]][file.type[1]] = fileList;
      }
    },
    //上传
    uploadFile(files){
      // 创建新的数据对象
      let formData = new FormData();
      formData.append('auth_img', files.file);
      this.$instance.post('/user/pre_post_auth_img',formData,
        {
          timeout: 300000,
        } 
      ).then(res=>{
        if(res.data.code==0){
          let result=res.data.data.map((v)=>{return{url:v.img_url,img_relative_file_path:v.img_relative_file_path,type:files.data.type}})  
          this.$set(this.form[files.data.type[0]],files.data.type[1],(this.form[files.data.type[0]][files.data.type[1]]||[]).concat(result))
        }else{
          this.$set(this.form[files.data.type[0]],files.data.type[1],(this.form[files.data.type[0]][files.data.type[1]]||[]).concat([]))
        }
      })
      .catch(()=>{
        this.$set(this.form[files.data.type[0]],files.data.type[1],(this.form[files.data.type[0]][files.data.type[1]]||[]).concat([]))
      })
    },
    //提交订单=================================================================================
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitData()
        } else {
          this.$message({message: '有必填项未填写，请完善资料!',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          return false;
        }
      });
    },
    submitData(){
      let form={...this.form,dw_type:this.dwType,...this.$store.state.basicParams,}
       
      for(let key in form){
        if(this.$dataType(form[key])=='object'){
          form[key]={...form[key]}
          for(let key_sub in form[key]){
            if(key_sub.indexOf('imgs')>=0&&this.$dataType(form[key][key_sub])=='array'){
              form[key][key_sub]=form[key][key_sub].map(v=>{return v.img_relative_file_path})
            }
          }
        }
      }
      this.$emit('submit',form)
    },
    setValue(){
      if(this.dwType==this.formData.dw_type){
        for(let key in this.formData){
          if(this.$dataType(this.formData[key])=='object'){
            this.form[key]={...this.formData[key]}
          }else if(this.$dataType(this.formData[key])=='array'){
            this.form[key]=[...this.formData[key]]
          }else{
            this.form[key]=this.formData[key]
          }
        }
      }
      // else{
      //   this.form.zizhi_id=this.formData.zizhi_id||undefined
      // }
    }
  },
  created(){
   this.setValue()
  },
  mounted(){ 
    
  },
  beforeDestroy() {
   
  },
  watch: {
    'formData':{
      handler:function(){
        this.setValue()
      },
      deep:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
<style>

</style>
